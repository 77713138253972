.featured-projects-callout {
  .item {
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 560px;

    position: relative;
    background-position: center;
    height: 100%;
    transition: all .5s ease-in-out;
    overflow: hidden;
    cursor: none;

    /*&:after {
      content: '\A';
      position: absolute;
      width: 100%; height:100%;
      top:0; left:0;
      background:rgba(0,0,0,0.5);
      opacity: 1;
      transition: all 1s;
      -webkit-transition: all 1s;
    }*/

    &:hover{
      transform: scale(1.1);
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -ms-transform: scale(1.1);
      -o-transform: scale(1.1);
      overflow: hidden;
      box-shadow: none;
      .description{
        opacity: 0;
      }
    }
  }
  .project-item{
    padding: 0;
    overflow: hidden;
  }
  .description{
    position: relative;
    color: $white;
    height: 100%;
    overflow: hidden;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -ms-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
    opacity: 1;
  }
  .title{
    position: absolute;
    top: 45%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-family: $miller;
    font-size: 33px;
    max-width: 300px;
    line-height: 100%;
  }
  .location{
    position: absolute;
    bottom: 50px;
    text-align: center;
    width: 100%;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 7px;
  }

}