.single-about-slider-callout {
  height: calc(100vh - 80px);

  .column-container {
    height: calc(100vh - 80px);
  }

  .content-wrapper {
    position: relative;
    height: calc(100vh - 80px);
  }

  .information {
    position: absolute;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .image-content {
    min-height: 700px;
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-color: #313131;
  }

  .container {
    position: relative;
  }

  .title-animated {
    text-align: center;
    margin: 0 auto;
    max-width: 370px;
  }

  h1 {
    font-size: 58px;
    color: $white;
  }

  h3 {
    max-width: 300px;
    margin: 0 auto;
  }

  .content-wrapper {
    position: relative;
    min-height: 700px;

    .content {
      max-width: 300px;
      border-left: 1px solid $brown;
      padding-left: 15px;
      margin-top: 100px;
    }
  }


  .svg-image {
    top: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
      max-width: 345px;
    }
  }

  .opacity-circle {
    svg {
      opacity: 0.4;
    }
  }

  .dark-overlay {
    .image-content {
      box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
    }
  }

}

svg * {
  fill: none;
  stroke: currentColor;
}

