.blog-left-callout {
  .title {
    max-width: 450px;
    position: absolute;
    left: -60px;
    top: -75px;

    h2 {
      color: $brown;
    }
  }

  .content-wrapper {
    max-width: 300px;
    border-left: 1px solid $brown;
    padding-left: 15px;
    margin-top: 50px;
    margin-left: 30px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    margin-bottom: 20px;

    .content {
      color: $white;
    }

    .date {
      color: $brown;
      padding-bottom: 20px;
    }

  }

  .paroller-wrapper {
    width: 100%;
    max-height: 540px;
    overflow: hidden;

    .paroller {
      width: 100%;
      min-height: 540px;
      background-size: cover;
      background-position: bottom center;
      position: relative;
      background-repeat: no-repeat;
    }
  }
}