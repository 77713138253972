// Extra small devices (portrait phones, less than 576px)
@include media-breakpoint-up(xs) {

}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {

}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {

}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {

}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {
  .single-project {
    .entry-header {
      .title {
        .name {
          font-size: 80px;
        }
      }
    }
  }
  .the-yorkdale-condominiums{
    .entry-header{
      .title{
        .name{
          font-size: 80px;
        }
      }
    }
  }
}

@media screen and (min-width: 1500px) {
 
  .container {
    max-width: 1440px;
  }
  .container-sm {
    max-width: 850px;
  }
  .container-lg {
    max-width: 1200px;
  }
  .container-md {
    max-width: 1010px;
  }
}

@media screen and (min-height: 1079px) {
  .featured-team-callout {
    min-height: 1080px;

    .container-md {
      min-height: 1080px;
    }
  }
}