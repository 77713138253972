.blog-slider-callout {
  min-height: calc(100vh - 80px);
  background-color: $light-grey;
  color: $white;
  overflow: hidden;

  .text-content {
    min-height: calc(100vh - 80px);
    position: relative;
  }

  .text-wrapper {
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;

    .date {
      color: $brown;
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    h3 {
      color: $white;
      min-height: 110px;
    }

    a{
      outline: none;
    }
  }

  .side-text-left {
    z-index: 5;
  }

  .down-arrow {
    z-index: 10;
  }

  .container {
    position: relative;
  }

  .container-md {
    min-height: calc(100vh - 80px);;
    position: relative;
    max-width: 900px;
  }

  .content-wrapper {
    max-width: 600px;
    position: absolute;
    top: 55%;
    transform: translate(0%, -50%);
    padding-left: 15px;
  }

  .image-wrapper {
    max-width: 500px;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .content {
    max-width: 400px;

    .more {
      padding: 0 15px;
      border-left: 1px solid $brown;
    }
  }

  .letter {
    font-family: $miller;
    font-weight: 300;
    font-style: normal;
    margin-bottom: 0;
    font-size: 690px;
    position: absolute;
    color: $light-black;
    bottom: 0;
    text-transform: uppercase;
    z-index: 1;
    line-height: 0;
    height: 230px;
    -webkit-animation: moveToLeft 50s linear infinite;
    animation: moveToLeft 50s linear infinite;
  }

  @-webkit-keyframes moveToLeft {
    from {
      left: 100%;
    }
    to {
      left: -3500px;
    }
  }

  @keyframes moveToLeft {
    from {
      left: 100%;
    }
    to {
      left: -3500px;
    }
  }

  .slick-dots {
    li button:before {
      width: 20px;
      height: 20px;
      font-size: 20px;
      line-height: 20px;
      opacity: 1;
      color: $white;
    }
    .slick-active{
      button:before{
        color: $brown;
      }
    }
  }

  .vertical-line {
    background-color: $brown;
    width: 1px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 5;

    &.top {
      height: 310px;
      top: 0;
    }

    &.bottom {
      bottom: 20px;
      height: 300px;
    }
  }

  .side-text-right {
    top: 60%;
  }

  .no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }
}