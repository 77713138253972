.featured-slider-fluid {
  .side-text {
    left: 40px;
    z-index: 2;
  }
  .f-projects-btn {
    font-size: 11px;
    display: block;
    padding: 4px 11px;
    width: fit-content;
    margin-top: 40px;
    text-transform: capitalize;
  }
  .arrows-btns {
    padding-top: 1rem;
    
  }
}
.featured-slider-callout {
  position: relative;
  height: calc(100vh - 80px);
  background-color: #fff;


  .featured-slider {
    .item {
      height: calc(100vh - 80px);
      background-size: cover;
      background-position: center center;
      position: relative;
      // box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, .3);
      .flex-old-slider {
        display: flex;
        .img-slide-flex {
          width: 66%;
          padding-right: 35px;
          padding-left: 35px;
        }
        .copy-side-flex {
          width: 33%;
          padding-left: 15px;
        }
      }
    }

    .title {
      // position: absolute;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);
      // max-width: 900px;
      // text-align: center;
      // width: 100%;

      .name {
        font-size: 60px;
        color: $brown;
        font-family: $miller;
        text-transform: capitalize;
        min-height: 86px;
        letter-spacing: -2px;
        line-height: 100%;
        max-width: 750px;
        margin: 30px auto;
      }

      .address {
        font-size: 17px;
        color: $white;
        letter-spacing: 8px;
        text-transform: uppercase;
        font-weight: 500;
        max-width: 650px;
        margin: 0 auto;
      }
    }
  }

  .slider-nav {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 9;
  }

  /* progress bar */
  .slider-progress {
    width: 400px;
    height: 2px;
    background: $brown;
    display: none;
  }

  .slider-progress .progress {
    width: 0;
    height: 2px;
    background: $white;
  }

  .slider-nav-items {
    .item {
      width: 400px;
      cursor: pointer;
    }

    .slick-slide {
      float: none;
      width: 400px !important;
      margin-bottom: 30px;
      outline: none;
    }

    .slick-track {
      width: 400px !important;
      transform: none !important;
    }

    .slick-current {
      .slider-progress {
        display: block;
      }

      .title {
        color: $brown;
      }
    }

    .title {
      padding-left: 150px;
      color: $white;
      cursor: pointer;
      text-transform: uppercase;
      text-align: right;
    }
  }
}
