* {
	outline: none !important;
}

/* Default Styles */

.page {
	// hide the meta on pages
	// do not remove this
	.entry-meta {
		display: none;
	}
}

// body
body {
	font-family: $default-font;
	font-weight: $default-font-weight;
	background: $body-bg;
	font-size: 15px;
	line-height: 22px;
}

body,
button,
input,
select,
textarea {
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4 {
	font-family: $miller;
	font-weight: 300;
	font-style: normal;
	margin-bottom: 0;
}

h2 {
	font-size: 56px;
	letter-spacing: -1px;
}

h3 {
	font-size: 28px;
	margin-bottom: 27px;
	line-height: 1.3;
}

// links
a {
	&:hover {
		text-decoration: none;
	}
}

.site {
	position: relative;
	overflow-x: hidden;
}

.site-content {
	padding-top: 80px;
}

// default container size
.container {
	max-width: 1330px;
}

// create additional containers
$container-list: ((sm, 850), (md, 1010), (lg, 1000), (xl, 1200));
@each $label, $max-width in $container-list {
	.container-#{$label} {
		max-width: #{$max-width}px;
	}
}

// hide the overflow
.no-overflow {
	overflow-x: hidden !important;
}

// hide padding
.no-padding {
	padding: 0 !important;
}

// full width image
.full-width-img {
	img {
		width: 100%;
		max-width: 100%;
	}
}

.video-icon {
	position: absolute;
	right: 30px;
	bottom: 30px;
	background: transparent;
	border: 2px solid #ffffff;
	color: #ffffff;
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	cursor: pointer;
	z-index: 11;
	@media (max-width: 768px) {
		width: 40px;
		height: 40px;
		right: 20px;
		bottom: 20px;
	}
}

.video-iconpay {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
	right: 0;
	display: flex;
	align-items: normal;
	justify-content: center;
	cursor: pointer;
	@media (max-width: 768px) {
		img {
			width: 40px;
		}
	}
	&.hideicons {
		opacity: 0;
		transition: all 0.4s ease-in-out;
	}
}

.grey-bg {
	background-color: $light-black;
}

.white-bg {
	background-color: $white;
}

.light-grey-bg {
	background-color: $light-grey;
}

.text-white {
	color: $white;
}

.text-brown {
	color: $brown;
}

.text-light-grey {
	color: $light-grey;
}

.relative-position {
	position: relative;
}

.border-left-brown {
	border-left: 1px solid $brown;
}

.border-right-brown {
	border-right: 1px solid $brown;
}

.widget {
	margin: 0;
}

.side-text {
	position: absolute;
	top: 50%;
	left: 10px;
	transform: translate(0, -50%) scale(-1);
	writing-mode: vertical-lr;
	text-orientation: mixed;
	.inner-text {
		transform: rotate(0);
		text-transform: uppercase;
		font-weight: 500;
		font-size: 14px;
		letter-spacing: 7px;
	}
}

.side-text-left {
	@extend .side-text;
	left: 0;
	margin-left: 40px;
}

.side-text-right {
	@extend .side-text;
	right: 0;
	left: unset;
	margin-right: 55px;
}

.side-text-right-small {
	@extend .side-text;
	right: 0;
	margin-right: -45px;
}

.down-arrow {
	position: absolute;
	bottom: 20px;
	left: 50%;
	transform: translate(-50%, 0);
	background-color: $brown;
	width: 20px;
	height: 36px;
	border-radius: 30px;
	.link-scroll {
		img {
			margin: 0 auto;
			text-align: center;
			padding-top: 12px;
		}
	}
	.bounce {
		-moz-animation: bounce 2s infinite;
		-webkit-animation: bounce 2s infinite;
		animation: bounce 2s infinite;
	}
	@keyframes bounce {
		0%,
		20%,
		50%,
		80%,
		100% {
			transform: translateY(0);
		}
		40% {
			transform: translateY(-5px);
		}
		60% {
			transform: translateY(-10px);
		}
	}
}

.video-wrap {
	position: relative;
	@include media-breakpoint-up(xl) {
		padding: 0 10%;
	}
	.video-block {
		position: relative;
	}
	.discover {
		position: absolute;
		bottom: -60px;
		left: 50%;
		z-index: 3;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
		text-transform: uppercase;
		letter-spacing: 0.35em;
		color: #fff;
		font-size: 12px;
		@include media-breakpoint-up(md) {
			bottom: 88px;
			color: #fff;
		}
		&:after {
			// content: url("/wp-content/uploads/2024/06/Frame-921.svg");
			content: url("/wp-content/uploads/2024/04/discover.svg");
			position: absolute;
			top: 100%;
			left: 50%;
			-webkit-transform: translateX(-50%) translateY(0);
			transform: translateX(-50%) translateY(0);
			-webkit-animation: MoveUpDown 3s linear infinite;
			animation: MoveUpDown 3s linear infinite;
			@include media-breakpoint-up(md) {
				content: url("/wp-content/uploads/2024/04/discover.svg");
			}
		}
	}
}

.hiddenText {
	display: none;
}

.read-more {
	color: $brown;
	margin-top: 10px;
	cursor: pointer;
}

.letters {
	font-family: $miller;
	font-weight: 300;
	font-style: normal;
	margin-bottom: 0;
	font-size: 130px;
	position: absolute;
	color: $brown;
	left: 25px;
	&.t {
		top: 700px;
	}
	&.e {
		top: 1380px;
	}
	&.a {
		top: 2000px;
		left: 20px;
	}
	&.m {
		top: 2700px;
		left: 5px;
	}
}

.brown-footer {
	.site-footer {
		border-top: 0.5px solid $brown;
	}
}

.plus {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 99;
	pointer-events: none;
	opacity: 0;
	width: 50px;
}

.aplus {
	opacity: 1;
}

.full-black-bg {
	background-color: $light-black;
}

.image-content-callout.small-container-image {
	.image-content {
		background-size: contain;
	}
}

.single-project.postid-104 {
	.paroller-wrapper {
		width: 100%;
		max-height: 600px;
		overflow: hidden;
		.paroller {
			min-height: 600px;
		}
	}
}

.contact {
	h3 {
		color: $white;
	}
	h2 {
		color: $white;
		font-size: 28px;
		margin-bottom: 27px;
		line-height: 1.3;
	}
	h1 {
		color: $white;
		font-size: 56px;
		letter-spacing: -1px;
	}
}

.join-the-team-callout {
	background-color: $white;
	.flex-join {
		padding-top: 128px;
		padding-left: 128px;
		padding-right: 128px;
		padding-bottom: 104px;
		display: flex;
		.join-title {
			width: 55%;
			position: relative;
			h2 {
				color: $brown;
				text-transform: uppercase;
				font-size: 56px;
				font-weight: 300;
			}
			&::before {
				content: "";
				width: 1px;
				height: 100%;
				background-color: $primary;
				position: absolute;
				right: 0;
				top: 0;
			}
		}
		.join-content {
			width: 45%;
			position: relative;
			padding-left: 80px;
			h4 {
				font-size: 30px;
				color: $silver;
				margin-bottom: 8px;
			}
			p {
				margin-bottom: 16px;
			}
			a {
				color: $white;
			}
		}
	}
	.fullimg {
		width: 100%;
		img {
			width: 100%;
		}
	}
}

.broker-page-template {
	background-color: #fff;
	.after-pass {
		display: none;
	}
	.password-area {
		padding-bottom: 80px;
		display: flex;
		h3 {
			font-size: 32px;
			text-align: left;
			margin-bottom: 40px;
			width: 50%;
		}
		h2 {
			font-size: 32px;
			text-align: left;
			margin-bottom: 40px;
			width: 50%;
			line-height: 1.3;
			letter-spacing: unset;
		}
		.pass-section {
			width: 50%;
		}
		p {
			font-size: 16px;
			margin-bottom: 13px;
			span {
				color: #ff0000;
			}
		}
		.wrong-pass {
			display: none;
		}
		input {
			display: block;
			width: 502px;
			max-width: 100%;
			padding: 5px;
			// margin-bottom: 40px;
		}
		.btn-primary {
			padding: 8px 22px;
			margin-top: 40px;
		}
		.pass-block {
			margin-bottom: 40px;
		}
	}
	h1 {
		text-align: center;
		margin-top: 80px;
		margin-bottom: 64px;
		text-transform: uppercase;
		font-size: 56px;
		font-family: "miller-banner", serif;
	}
	.line-with-flex {
		width: 920px;
		max-width: 100%;
		display: flex;
		align-items: center;
		margin: auto;
		margin-bottom: 80px;
		h3 {
			font-size: 32px;
			padding-right: 48px;
			margin-bottom: 0;
		}
		p {
			margin-bottom: 0;
		}
		.with-line-left {
			padding-left: 48px;
			position: relative;
			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 1px;
				height: 100%;
				background-color: $silver;
			}
		}
	}
	.flex-portals {
		display: flex;
		flex-wrap: wrap;
		.portal-block {
			width: 50%;
			padding: 0 32px;
			margin-bottom: 64px;
			.zoom-box {
				overflow: hidden;
				position: relative;
				img {
					width: 100%;
					height: 360px;
					object-fit: cover;
					position: relative;
					transition: 1s;
				}
				&::after {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 2;
					background-color: rgba(0, 0, 0, 0.5);
				}
				.description {
					position: absolute;
					top: 25%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: fit-content;
					z-index: 3;
					h2 {
						text-align: center;
						font-family: "MillerBanner", serif;
						font-size: 42px;
						line-height: 1;
						text-transform: uppercase;
						margin-bottom: 16px;
						color: #fff;
					}
					p {
						text-align: center;
						width: 100%;
						font-weight: 500;
						text-transform: uppercase;
						letter-spacing: 0.35em;
						color: #fff;
					}
				}
				.btn-primary {
					position: absolute;
					bottom: 40px;
					left: 50%;
					transform: translateX(-50%);
					z-index: 4;
					text-align: center;
				}
				&:hover {
					img {
						transform: scale(1.1);
					}
				}
			}
		}
	}
}

.broker-page-internal-template {
	background-color: #fff;
	h1 {
		text-align: center;
		margin-top: 80px;
		margin-bottom: 24px;
		text-transform: uppercase;
		font-size: 56px;
		font-family: "miller-banner", serif;
	}
	h3 {
		font-family: "miller-banner", serif;
		font-size: 32px;
		text-align: center;
	}
	.show-portal-flex {
		margin-top: 80px;
		margin-bottom: 120px;
		display: flex;
		.left-img {
			width: 50%;
			position: relative;
			height: fit-content;
			img {
				width: 100%;
				height: 360px;
				object-fit: cover;
				position: relative;
				transition: 1s;
			}
			.description {
				position: absolute;
				top: 25%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: fit-content;
				z-index: 3;
				h2 {
					text-align: center;
					font-family: "MillerBanner", serif;
					font-size: 42px;
					line-height: 1;
					text-transform: uppercase;
					margin-bottom: 16px;
					color: #fff;
				}
				p {
					text-align: center;
					width: 100%;
					font-weight: 500;
					text-transform: uppercase;
					letter-spacing: 0.35em;
					color: #fff;
				}
			}
			.btn-primary {
				position: absolute;
				bottom: 40px;
				left: 50%;
				transform: translateX(-50%);
				z-index: 4;
				text-align: center;
				padding: 0 12px;
			}
			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 2;
				background-color: rgba(0, 0, 0, 0.5);
			}
		}
		.right-side-portal {
			width: 50%;
			margin-left: 64px;
			padding-left: 64px;
			position: relative;
			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 1px;
				height: 100%;
				background-color: $silver;
			}
			h3 {
				font-size: 32px;
				text-align: left;
				margin-bottom: 40px;
			}
			p {
				font-size: 16px;
				margin-bottom: 13px;
				span {
					color: #ff0000;
				}
			}
			input {
				display: block;
				width: 502px;
				max-width: 100%;
				padding: 5px;
				// margin-bottom: 40px;
			}
			.btn-primary {
				padding: 8px 22px;
				margin-top: 40px;
			}
			.pass-block {
				margin-bottom: 40px;
			}
		}
	}
	.accordion-box {
		.each-accordion {
			border-bottom: 1px solid #2d2d2d;
			& > div {
				overflow: hidden;
				max-height: 0;
				transition: 1s;
				height: auto;
				&.open {
					max-height: 1000px;
					overflow-x: scroll;
				}
				ul {
					list-style-type: none;
					li {
						border-bottom: 1px solid #2d2d2d;
						padding: 24px 0;
						&:last-child {
							border-bottom: 0;
						}
					}
					.flex-line {
						display: flex;
						justify-content: space-between;
						p {
							letter-spacing: 0.35em;
						}
						.right-side {
							display: flex;
							align-items: end;
							a {
								padding-right: 10px;
							}
							& > img {
								width: 220px;
								height: 136px;
								object-fit: cover;
							}
						}
					}
				}
			}
			button {
				width: 100%;
				border: 0;
				background: transparent;
				text-align: left;
				padding: 24px 0;
				font-size: 16px;
				text-transform: uppercase;
				font-weight: 800;
				letter-spacing: 0.35em;
				position: relative;
				&:focus {
					outline: none;
				}
				&::before {
					content: "";
					position: absolute;
					width: 16px;
					height: 1px;
					background-color: #2d2d2d;
					top: 50%;
					right: 20px;
					transform: translateY(-50%);
				}
				&::after {
					content: "";
					position: absolute;
					width: 1px;
					height: 16px;
					background-color: #2d2d2d;
					top: 50%;
					right: 27px;
					transform: translateY(-50%);
					transition: 1s;
					opacity: 1;
				}
				&.open {
					&::after {
						opacity: 0;
					}
				}
			}
		}
	}
	.only-login {
		display: block;
	}
	.password-area {
		display: none;
	}
	.wrong-pass {
		display: none;
		color: red;
	}
}

.about-us-template {
	.banner-about {
		@include media-breakpoint-up(xl) {
			min-height: calc(42vh - 80px);
		}
		.container {
			min-height: calc(100vh - 80px);
			.row {
				min-height: calc(100vh - 80px);
				.col-12 {
					min-height: calc(100vh - 80px);
					.inner-text {
						color: #fff;
					}
					@keyframes MoveUpDown {
						0%,
						100% {
							transform: translateX(-50%) translateY(0);
						}
						50% {
							transform: translateX(-50%) translateY(10px);
						}
					}
					.discover {
						position: absolute;
						bottom: 88px;
						left: 50%;
						transform: translateX(-50%);
						text-transform: uppercase;
						letter-spacing: 0.35em;
						color: #fff;
						font-size: 12px;
						&::after {
							content: url("/wp-content/uploads/2024/04/discover.svg");
							position: absolute;
							top: 100%;
							left: 50%;
							transform: translateX(-50%) translateY(0);
							animation: MoveUpDown 3s linear infinite;
						}
					}
					.copy-banner-about {
						position: absolute;
						top: 45%;
						left: 50%;
						transform: translate(-50%, -50%);
						width: 100%;
						h2 {
							text-align: center;
							color: #fff;
							font-size: 80px;
							text-transform: uppercase;
							padding: 32px 0;
							line-height: 1;
							margin: 0;
							position: relative;
							&::after {
								content: "";
								width: 215px;
								height: 1px;
								background-color: #fff;
								position: absolute;
								top: 100%;
								left: 50%;
								transform: translateX(-50%);
							}
							&:last-child {
								&::after {
									display: none;
								}
							}
						}
					}
				}
			}
		}
	}
	.video-about {
		display: none;
	}
	.container-scroll-tl {
		width: 100%;
		height: 400vh;
		min-height: calc(600px * 4);
		position: relative;
		background-color: #fff;
		.screen-block-to-move {
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			height: 100vh;
			min-height: 600px;
			.first-hor-scr {
				min-height: 100vh;
				height: 100%;
				width: 100vw;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				padding: 0 30px;
	
				h2 {
					font-size: 80px;
					text-transform: uppercase;
					color: #2d2d2d;
					margin-bottom: 80px;
					line-height: 1;
					text-align: center;
					transition: 0.5s;
				}
				.flex-in-slide {
					display: flex;
					width: 1010px;
					max-width: calc(100% - 30px);
					h4 {
						font-size: 32px;
						color: #2d2d2d;
						transition: 0.5s;
						line-height: 1;
						padding-right: 0px;
						width: 100%;
					}
					h3 {
						font-size: 32px;
						color: #2d2d2d;
						transition: 0.5s;
						line-height: 1;
						padding-right: 0px;
						width: 100%;
					}
					.right-side {
						padding-left: 57px;
						position: relative;
						&::before {
							content: "";
							position: absolute;
							height: 100%;
							width: 1px;
							left: 0;
							top: 0;
							transition: 0.5s;
							background-color: #2d2d2d;
						}
						p {
							margin-bottom: 0;
							transition: 0.5s;
						}
					}
				}
				&.white {
					background-color: #fff;
				}
				&.grey {
					background-color: #cccccc;
				}
				&.dark {
					background-color: #313131;
					h3 {
						color: #fff;
					}
					.flex-in-slide {
						h4 {
							color: #fff;
						}
						.right-side {
							&::before {
								background-color: #fff;
							}
							p {
								color: #fff;
							}
						}
					}
				}
			}
		}
		.hor-line-tra {
			display: flex;
			transition: background-color 1s;
			background-color: #fff;
			&.white-bg-custom {
				background-color: #fff;
				.first-hor-scr {
		
					h2 {
						color: #2d2d2d;
					}
					.flex-in-slide {
						h4 {
							color: #2d2d2d;
						}
						h3 {
							color: #2d2d2d;
						}
						.right-side {
							&::before {
								background-color: #2d2d2d;
							}
							p {
								color: #2d2d2d;
							}
						}
					}
				}
			}
			&.grey-bg-custom {
				background-color: #ccc;
				.first-hor-scr {
			
					h2 {
						color: #2d2d2d;
					}
					.flex-in-slide {
						h4 {
							color: #2d2d2d;
						}
						h3 {
							color: #2d2d2d;
						}
						.right-side {
							&::before {
								background-color: #2d2d2d;
							}
							p {
								color: #2d2d2d;
							}
						}
					}
				}
			}
			&.dark-bg-custom {
				background-color: #313131;
				.first-hor-scr {
					
					h2 {
						color: #fff;
					}
					.flex-in-slide {
						h4 {
							color: #fff;
						}
						h3 {
							color: #fff;
						}
						.right-side {
							&::before {
								background-color: #fff;
							}
							p {
								color: #fff;
							}
						}
					}
				}
			}
		}
	}
	.full-page-slider-with-color {
		height: 100%;
		padding: 150px 0;
		ul.slick-dots {
			display: none;
		}
		@include media-breakpoint-up(md) {
			padding: 200px 0;
		}
		.slide {
			// min-height: 100vh;
			height: 100%;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			h1 {
				font-size: 56px;
				text-transform: uppercase;
				color: #ffffff;
				margin-bottom: 20px;
				line-height: 1;
				text-align: center;
				@include media-breakpoint-up(md) {
					margin-bottom: 80px;
				}
			}
			h3 {
				font-size: 56px;
				text-transform: uppercase;
				color: #ffffff;
				margin-bottom: 20px;
				line-height: 1;
				text-align: center;
				@include media-breakpoint-up(md) {
					margin-bottom: 80px;
				}
			}
			.flex-in-slide {
				display: flex;
				width: 100%;
				max-width: calc(100% - 30px);
				@include media-breakpoint-up(md) {
					width: 80%;
					max-width: calc(100% - 30px);
				}
				@include media-breakpoint-up(xl) {
					width: 60%;
				}
				@media (min-width: 1680px) {
					width: 50%;
				}
				@media (min-width: 1920px) {
					width: 40%;
				}
				@media (min-width: 2250px) {
					width: 30%;
				}
				h4 {
					font-size: 32px;
					color: #ffffff;
					line-height: 1;
					padding-right: 0px;
					width: 100%;
				}
				.right-side {
					// padding-left: 57px;
					position: relative;
					&::before {
						content: "";
						position: absolute;
						height: 100%;
						width: 1px;
						left: 0;
						top: 0;
						background-color: #2d2d2d;
					}
					p {
						margin-bottom: 0;
						color: #ffffff;
						text-align: center;
					}
				}
			}
			&.white {
				background-color: #fff;
			}
			&.grey {
				background-color: #cccccc;
			}
			&.dark {
				background-color: #313131;
				h3 {
					color: #fff;
				}
				.flex-in-slide {
					h4 {
						color: #fff;
					}
					.right-side {
						&::before {
							background-color: #fff;
						}
						p {
							color: #fff;
						}
					}
				}
			}
		}
	}
	.slick-dots {
		bottom: 30px;
		li {
			width: 8px;
			height: 8px;
			&.slick-active {
				button {
					background-color: #313131;
				}
			}
			button {
				width: 8px;
				height: 8px;
				background-color: #cccccc;
				border-radius: 50%;
				border: 1px solid #fff;
				&::before {
					display: none;
				}
			}
		}
	}
	.slick-dotted.slick-slider {
		margin: 0;
	}
	.video-about {
		padding: 120px 0;
		background-color: #fff;
		.video-div {
			position: relative;
			&.play {
				.overlay {
					opacity: 0;
				}
			}
			.overlay {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 2;
				background-image: url("/wp-content/uploads/2024/04/video-bg.jpg");
				background-size: cover;
				opacity: 1;
				transition: 0.5s;
			}
			.play-now {
			}
		}
	}
}

@media (max-aspect-ratio: 16/9) {
	/*the viewport is too narrow for the full video */
	.home-template {
		.container-vimeo {
			iframe {
				--h: 100vh;
				--w: calc(var(--h) * var(--videoRatio));
				top: 0;
				left: calc(50% - (var(--w) / 2));
				max-width: unset;
			}
		}
	}
}

.home-template {
	.container-vimeo {
		--videoRatio: calc(16 / 9);
		/* CHANGE THIS IF THE VIDEO CHANGES */
		padding: 0;
		margin: 0;
		width: 100vw;
		max-width: calc(100% + 30px);
		height: calc(100vh - 80px);
		overflow: hidden;
		position: relative;
		// background-image: url('/wp-content/uploads/2023/09/video-preview-high-scaled.jpg');
		// background-size: cover;
		iframe {
			background-image: url("/wp-content/uploads/2024/04/metropia-video-bg.jpg");
			background-size: cover;
			padding: 0;
			margin: 0;
			position: relative;
			--w: 100vw;
			--h: calc(var(--w) / var(--videoRatio));
			height: var(--h);
			width: var(--w);
			top: calc(50% - (var(--h) / 2));
			left: 0;
		}
		.video-overlay {
			background-color: #000;
			opacity: 0.45;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
			transition: 0.5s;
		}
		.slick-dots {
			bottom: unset;
			top: calc(100% + 40px);
			li {
				width: 8px;
				height: 8px;
				&.slick-active {
					button {
						background-color: #313131;
					}
				}
				button {
					width: 8px;
					height: 8px;
					background-color: #cccccc;
					border-radius: 50%;
					border: 1px solid #fff;
					&::before {
						display: none;
					}
				}
			}
		}
		.discover {
			position: absolute;
			bottom: 88px;
			left: 50%;
			z-index: 3;
			transform: translateX(-50%);
			text-transform: uppercase;
			letter-spacing: 0.35em;
			color: #fff;
			font-size: 12px;
			&::after {
				content: url("/wp-content/uploads/2024/04/discover.svg");
				position: absolute;
				top: 100%;
				left: 50%;
				transform: translateX(-50%) translateY(0);
				animation: MoveUpDown 3s linear infinite;
			}
		}
	}
	.featured-slider-fluid {
		background-color: #fff;
		padding: 120px 0;
		position: relative;
		.arrows-btns-mob {
			display: none;
		}
	}
	.main-slide-cont {
		position: absolute;
		z-index: 5;
		top: 40%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 1250px;
		max-width: 100%;
		font-family: "Avenir";
		.main-slider {
			.slide {
				display: flex;
				align-items: center;
				justify-content: center;
				width: fit-content;
				&.slick-active {
					.left-side {
						h2 {
							transform: translateX(0%);
						}
					}
					.right-side {
						p {
							transform: translateX(0%);
						}
						h2 {
							transform: translateX(0%);
						}
						h1 {
							transform: translateX(0%);
						}
					}
				}
				.left-side {
					color: #fff;
					padding-right: 48px;
					position: relative;
					overflow: hidden;
					&::after {
						content: "";
						position: absolute;
						top: 0;
						right: 0;
						height: 100%;
						width: 1px;
						background-color: #fff;
					}
					h2 {
						color: #fff;
						font-size: 136px;
						font-family: "Avenir";
						transform: translateX(150%);
						transition: 2s;
						transition-delay: 0.3s;
						span {
							font-size: 128px;
							letter-spacing: -0.05em;
						}
					}
				}
				.right-side {
					padding-left: 48px;
					overflow: hidden;
					p {
						color: #fff;
						font-size: 40px;
						margin-bottom: 0;
						line-height: 1.2;
						transform: translateX(-150%);
						transition: 2s;
						transition-delay: 0.6s;
					}
					h1 {
						color: #fff;
						font-size: 136px;
						display: flex;
						align-items: center;
						font-family: "Avenir";
						transform: translateX(-150%);
						transition: 2s;
						transition-delay: 0.6s;
						span {
							display: block;
							font-size: 60px;
							letter-spacing: -0.05em;
							text-align: left;
							line-height: 1;
							padding-left: 20px;
						}
					}
					h2 {
						color: #fff;
						font-size: 136px;
						display: flex;
						align-items: center;
						font-family: "Avenir";
						transform: translateX(-150%);
						transition: 2s;
						transition-delay: 0.6s;
						span {
							display: block;
							font-size: 60px;
							letter-spacing: -0.05em;
							text-align: left;
							line-height: 1;
							padding-left: 20px;
						}
					}
				}
			}
		}
	}
	.legacy-slider {
		.legacy-bg {
			min-height: 100vh;
			height: 100%;
			background-image: url("/wp-content/uploads/2024/04/leg-scaled.jpg");
			background-size: cover;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			.flex-legacy {
				display: flex;
				justify-content: center;
				align-items: self-start;
				height: 100%;
				width: 1115px;
				max-width: 100%;
				.left-side {
					width: 45%;
					h2 {
						color: #fff;
						font-size: 56px;
						line-height: 1;
						text-transform: uppercase;
					}
				}
				.right-side {
					width: 55%;
					padding-left: 80px;
					position: relative;
					padding-bottom: 40px;
					&::after {
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						width: 1px;
						height: 100%;
						background-color: #fff;
					}
					p {
						color: #fff;
						margin-bottom: 40px;
					}
					a {
						padding: 4px 12px;
					}
				}
			}
		}
		.awards-bg {
			min-height: 100vh;
			height: 100%;
			background-color: #e6e6e6;
			display: flex;
			align-items: center;
			justify-content: center;
			.flex-awards {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 1385px;
				max-width: 100%;
				.left-side {
					width: 40%;
					padding-right: 70px;
					img {
						width: 100%;
					}
				}
				.right-side {
					width: 60%;
					padding-left: 70px;
					position: relative;
					&::after {
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						width: 1px;
						height: 100%;
						background-color: #8c9091;
					}
					.award {
						margin-bottom: 48px;
						&:last-child {
							margin-bottom: 0;
						}
						h3 {
							font-size: 42px;
							color: #2d2d2d;
							margin-bottom: 0;
							text-transform: uppercase;
						}
						h5 {
							font-size: 18px;
							color: #8c9091;
							text-transform: uppercase;
						}
						h4 {
							font-size: 18px;
							color: #8c9091;
							text-transform: uppercase;
							font-weight: 500;
							font-family: "Avenir", sans-serif;
						}
					}
				}
			}
		}
		.slick-dots {
			bottom: 30px;
			li {
				width: 8px;
				height: 8px;
				&.slick-active {
					button {
						background-color: #313131;
					}
				}
				button {
					width: 8px;
					height: 8px;
					background-color: #cccccc;
					border-radius: 50%;
					border: 1px solid #fff;
					&::before {
						display: none;
					}
				}
			}
		}
	}
	.slick-dotted.slick-slider {
		margin: 0;
	}
	.instagram-bg {
		background-color: #fff;
		padding: 120px 0;
		.instgram-flex {
			display: flex;
			flex-direction: column;
			.insta-top-flex {
				display: flex;
				justify-content: space-between;
				h2 {
					font-size: 56px;
					text-transform: uppercase;
					margin-bottom: 0;
				}
				.right-side {
					display: flex;
					align-items: center;
					.icon0link-flex {
						display: flex;
						align-items: center;
						p {
							margin-bottom: 0;
							font-size: 16px;
							font-weight: 800;
							padding-left: 20px;
							line-height: 1;
							padding-right: 48px;
						}
					}
					a {
						padding: 8px 14px;
					}
				}
			}
			.instagram-images {
				display: flex;
				flex-wrap: wrap;
				margin-top: 56px;
				.img-no-hov {
					width: 20%;
					padding: 5px;
				}
				.img-hover {
					overflow: hidden;
					img {
						width: 100%;
						height: 400px;
						object-fit: cover;
						transform: scale(1);
						transition: 1s;
					}
					&:hover {
						img {
							transform: scale(1.2);
						}
					}
				}
			}
		}
	}
}

.privacy-policy {
	h2 {
		color: $silver;
		font-size: 1.5rem;
	}
	h1 {
		color: $silver;
		font-size: 56px;
		letter-spacing: -1px;
	}
}

.thank-you {
	h2 {
		color: $silver;
	}
	h1 {
		color: $silver;
		font-size: 56px;
		letter-spacing: -1px;
	}
}
div#controls button {
	background: transparent;
	border: 0;
}

/* Basic styling for the progress bar */
#progress-container {
	width: 100%;
	height: 10px;
	background-color: #e0e0e0;
	position: relative;
	cursor: pointer;
	margin-top: 10px;
}
#progress-bar {
	height: 100%;
	width: 0;
	background-color: #000000;
}
/* Styling for the control buttons */
#controls {
}
#play-pause-buttons {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	&.hideicons {
		opacity: 0;
		transition: all 0.4s ease-in-out;
	}
	img {
		width: 50px;
		@include media-breakpoint-up(md) {
			width: 150px;
		}
		@include media-breakpoint-up(xl) {
			width: auto;
		}
	}
}
#mute-unmute-buttons {
	position: absolute;
	bottom: 40px;
	right: 30px;
	button {
		border: 2px solid #ffffff !important;
		width: 30px;
		height: 30px;
		border-radius: 100%;
		@include media-breakpoint-up(xl) {
			width: 50px;
			height: 50px;
		}
		i {
			color: #ffffff;
		}
	}
}
#controls button {
	margin-right: 10px;
}
iframe {
	display: block;
	margin: 0 auto;
}
