.projects-archive-callout {
  h1 {
    color: #fff;
    text-transform: uppercase;
    font-size: 56px;
    letter-spacing: -1px;
  }
  h2 {
    color: #fff;
    text-transform: uppercase;
  }
  .filters-button-group {
    text-align: center;
    margin: 0 auto;
    display: block;
    padding-bottom: 45px;

    .button {
      background: transparent;
      border: none;
      color: $white;
      padding: 0;
      margin-right: 50px;
      text-transform: uppercase;
      font-weight: 500;
      outline: none;
      letter-spacing: 3px;

      &:last-child {
        margin-right: 0;
      }
    }

    .is-checked {
      color: $silver;
      border-bottom: 1px solid $silver;
    }
  }

  .single-project-item {
    padding: 0;
    margin-bottom: 80px;
  }

  .side-text, .side-text-left {
    top: 500px;
  }

  .item {
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 650px;
    position: relative;
    background-position: center;
    height: 100%;
    transition: all .5s ease-in-out;
    overflow: hidden;
    cursor: none;

    &:hover {
      transform: scale(1.1);
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -ms-transform: scale(1.1);
      -o-transform: scale(1.1);
      overflow: hidden;
      box-shadow: none;

      .description {
        opacity: 0;
      }
      .units {
        opacity: 0;
      }
    }
  }

  .project-item {
    margin: 0 4px;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 650px;
    box-shadow: none;
    position: relative;
    background-position: center;
    height: 100%;
    transition: all .5s ease-in-out;
    overflow: hidden;
  }

  .description {
    position: relative;
    color: $white;
    // height: 500px;
    overflow: hidden;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -ms-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
    opacity: 1;
  }
  .units {
    position: absolute;
    bottom: 10%;
    left: 50%;
    width: 95%;
    transform: translate(-50%, 0%);
    opacity: 1;
    transition: opacity 1s ease-in-out;
    p {
      font-size: 24px;
      text-align: center;
      color: #fff;
    }
  }
  .description {
    position: absolute;
    top: 25%;
    left: 50%;
    width: 95%;
    transform: translate(-50%, 0%);
  }
  .title {
    text-align: center;
    font-family: $miller;
    font-size: 42px;
    line-height: 1;
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  .location {
    // position: absolute;
    // bottom: 50px;
    text-align: center;
    width: 100%;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 5px;
    // font-size: 20px;
  }
}