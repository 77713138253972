.headroom {
  will-change: transform;
  transition: transform 200ms linear;
}
.headroom--pinned {
  transform: translateY(0%);
}
.headroom--unpinned {
  transform: translateY(-100%);
}

.page.overflow{
  .headroom--unpinned {
    transform: none;
  }
}

.about{
  .headroom--unpinned {
    transform: none;
  }
}