.image-content-callout {
  .image-content {
    min-height: 700px;
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
  }
  .container{
    position: relative;
  }
  .title{
    padding-top: 116px;
    text-align: center;
  }
  h3{
    color: $brown;
  }
  a{
    color: unset;
  }
  .content-wrapper {
    position: relative;
    min-height: 700px;
    .content {
      max-width: 300px;
      border-left: 1px solid $brown;
      padding-left: 15px;
      margin-top: 100px;
    }
  }
}

.full-height{
  .image-content {
    height: calc(100vh - 80px);
  }
}