.not-found {
  padding: 200px 15px;
  text-align: center;
  h1{
    margin-bottom: 27px;
    color: $white;
  }
  p{
    color: $white;
  }
}

.error404 {
  background-color: $light-grey;
}