.blog-middle-callout{
  .image-wrapper{
    min-width: 310px;
    min-height: 310px;
    background-position: center;
    background-size: cover;
  }
  .content-wrapper{
    color: $white;
  }
  .middle-content {
    padding: 15px 30px;
  }
  .horizontal-line{
    height:1px;
    background-color: $brown;
    width: 400px;
    margin-bottom: 14px;
  }
  h3{
    margin-bottom: 25px;
  }
  .counter-2{
    .middle-content{
      padding-left: 0;
    }
    .horizontal-line{
      width: 2920px;
      margin-left: -1025px;
    }
  }
  .text-wrapper{
    position: relative;
  }
  .letter {
    font-family: $miller;
    font-weight: 300;
    font-style: normal;
    margin-bottom: 0;
    font-size: 130px;
    position: absolute;
    color: $brown;
    // top: 50%;
    top: 505px;
    z-index: 10;
    &.first{
      left: 400px;
    }
    &.last{
      left: unset;
      right: 390px;
    }
  }
}