.single-post {
  background-color: $light-black;

  .site-content {
    color: $white;

    .side-text, .side-text-left {
      top: 600px;
    }

    h2 {
      color: $brown;
      max-width: 500px;
      padding-bottom: 50px;
    }

    h3 {
      color: $brown;
      margin-bottom: 0;
    }

    .posted-on {
      a {
        color: $white;
      }
    }

    .entry-content {
      padding-left: 15px;
      border-left: 1px solid $brown;
      margin-bottom: 35px;
      padding-right: 50px;

      p:last-child {
        margin-bottom: 0;
      }
    }

    .featured-thumbnail {
      padding-bottom: 60px;
      padding-right: 20px;
    }
  }

  .site-footer {
    border-top: 0.5px solid $brown;
  }
}

.widget-area {
  h4 {
    color: $brown;
    padding-bottom: 35px;
  }

  .recommended-items {
    a {
      color: $white;
    }

    h5 {
      font-size: 15px;
    }

    .media {
      padding-top: 20px;
      padding-bottom: 20px;
      border-top: 1px solid $brown;

      &:last-child {
        border-bottom: 1px solid $brown;
      }
    }
  }

  .sidebar-newsletter {
    background-color: $light-grey;
    padding: 80px 30px 30px 30px;
    margin-bottom: 60px;
    margin-top: -90px;

    h4 {
      max-width: 150px;
    }

    .form-control {
      background-color: transparent;
      padding: 0;
      border-bottom: 1px solid $silver;
      color: $white;
    }

  }
}


.archive {
  background-color: $light-black;

  .site-footer {
    border-top: 0.5px solid $brown;
  }

  h2, h3 {
    color: $brown;
  }

  .entry-content {
    color: $white;
  }

  .single-post-archive {
    border-bottom: 1px solid $brown;
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
}