/*--------------------------------------------------------------
# Lity Customizations
--------------------------------------------------------------*/
.lity {
  background: rgba(0, 0, 0, 0.8);
}

.lity-close {
  position: absolute;
  right: 20px !important;
  top: 20px !important;
  background-color: rgba(0, 0, 0, 0.6);
}

.lity-close:hover,
.lity-close:focus,
.lity-close:active,
.lity-close:visited {
  background-color: rgba(0, 0, 0, 0.6);
}
.lity-iframe-container iframe,
.lity-content:after {
  box-shadow: none;
}