.site-footer {
  background-color: $light-black;

  h3 {
    color: $brown;
  }

  .contact-info {
    padding: 112px 0 62px 0;
    color: $white;
    font-size: 14px;
    line-height: 20px;
  }

  .brown-line {
    height: 1px;
    width: 100%;
    background-color: #fff;
  }
  .footer-flex {
    display: flex;
    justify-content: space-between;
  }


  .navigation {
    padding: 30px 0;

    a {
      color: $white;

      &:hover {
        color: $brown;
      }
    }

    p {
      margin-bottom: 0;
    }

    .disclaimer {
      color: $white;
      margin: 0 auto;
      font-weight: normal;
      text-align: right;
      font-size: 13px;
    }

    .footer-list {
      list-style: none;
      padding: 0;
      margin-bottom: 0;
      font-size: 13px;

      li {
        display: inline-block;
        text-transform: uppercase;
        padding-right: 24px;
        letter-spacing: 1px;

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }

  .border-widget {
    border-right: 1px solid $brown;
    border-left: 1px solid $brown;
  }
  .right-side {
    .contact-flex {
      display: flex;
      .widget-title {
        color: #fff;
        padding-right: 26px;
      }
      .address {
        padding-left: 26px;
        position: relative;
        &::before {
          content: '';
          height: 100%;
          width: 1px;
          position: absolute;
          left: 0;
          top: 0;
          background-color: #fff;
        }
        p {
          margin-bottom: 0;
          &:first-child {
            margin-bottom: 24px;
          }
          a {
            color: #fff;
          }
        }
      }
    }
  }
  .social-media {
    position: relative;
    bottom: 0;
    padding-top: 56px;
    .social-media-item {
      background-color: #fff;
    }
  }
}