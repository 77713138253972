.btn-primary{
  min-width: 111px;
  min-height: 28px;
  background-color: $primary;
  color: $white;
  border-radius: 30px;
  font-family: $miller;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 1.7;
  font-style: italic;
  transition: 0.5s;
  &:hover {
    color: $silver;
  }
}