@media only screen and (max-height: 725px) and (min-width: 576px) {
    .about-us-template {
        .banner-about {
            .container {
                .row {
                    .col-12 {
                        .copy-banner-about {
                            h2 {
                                font-size: 52px;
                                padding: 22px 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1919px) {
    .featured-team-callout {
        .vertical-line {
            display: none;
        }
        .letter {
            display: none;
        }
    }
    .blog-middle-callout {
        .letter {
            display: none;
        }
    }
}

@media only screen and (max-width: 1500px) {
    .home-template {
        .instagram-bg {
            .instgram-flex {
                .instagram-images {
                    .img-hover {
                        img {
                            height: 270px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1400px) {
    .featured-slider-callout {
        height: auto;
        .featured-slider {
            .item {
                height: auto;
            }
        }
        .slider-nav {
            display: none;
        }
        .slick-dots {
            bottom: 100px;
        }
        .slick-dots li button:before {
            width: 30px;
            height: 30px;
            font-size: 30px;
            color: $white;
        }
    }
}

// Large devices (desktops, less than 1200px)
@include media-breakpoint-down(lg) {
    .broker-page-template {
        .password-area {
            h3 {
                font-size: 24px;
            }
            h2 {
                font-size: 24px;
            }
        }
    }
    .broker-page-internal-template {
        .show-portal-flex {
            .right-side-portal {
                h3 {
                    font-size: 24px;
                }
            }
        }
        .accordion-box {
            .each-accordion>div {
                ul {
                    padding: 0;
                }
            }
        }
    }
    .home-template {
        .legacy-slider {
            .awards-bg {
                .flex-awards {
                    flex-direction: column;
                    padding: 0 30px;
                    .left-side {
                        width: fit-content;
                        padding-right: 0;
                        img {
                            width: 290px;
                            max-width: 100%;
                        }
                    }
                    .right-side {
                        width: fit-content;
                        padding-left: 0;
                        .award {
                            padding-bottom: 24px;
                            margin-bottom: 24px;
                            position: relative;
                            &::after {
                                content: '';
                                width: 100%;
                                height: 1px;
                                background-color: $silver;
                                position: absolute;
                                bottom: 0;
                                left: 0;
                            }
                            &:last-child {
                                &::after {
                                    display: none;
                                }
                            }
                        }
                        &::after {
                            display: none;
                        }
                    }
                }
            }
            .legacy-bg {
                .flex-legacy {
                    flex-direction: column;
                    padding: 0 30px;
                    .left-side {
                        width: 100%;
                        padding-bottom: 24px;
                    }
                    .right-side {
                        width: 100%;
                        padding-left: 0px;
                        padding-top: 24px;
                        &::after {
                            width: 100%;
                            max-width: 400px;
                            height: 1px;
                        }
                    }
                }
            }
        }
        .featured-slider-fluid {
            .f-projects-btn {
                margin: auto;
                margin-top: 40px;
            }
            .side-text {
                left: 0;
            }
            .featured-slider-callout {
                .featured-slider {
                    .item {
                        .flex-old-slider {
                            flex-direction: column-reverse;
                            .copy-side-flex {
                                width: 100%;
                                padding-bottom: 24px;
                                padding-left: 0px;
                                .infoarea {
                                    border-left: none;
                                    border-bottom: 1px solid #2D2D2D;
                                    padding-bottom: 16px;
                                    padding-left: 0;
                                    br {
                                        display: none;
                                    }
                                }
                                .arrows-btns {
                                    display: none;
                                }
                            }
                            .img-slide-flex {
                                width: 100%;
                                padding-right: 0;
                                padding-left: 0;
                                .arrows-btns-mob {
                                    display: block;
                                    display: flex;
                                }
                            }
                        }
                    }
                }
            }
        }
        .main-slide-cont {
            .main-slider {
                .slide {
                    flex-direction: column;
                    .left-side {
                        padding-right: 0;
                        &::after {
                            width: 100%;
                            height: 1px;
                            top: unset;
                            bottom: 0;
                        }
                        h2 {
                            transform: translateY(150%);
                        }
                    }
                    .right-side {
                        padding-left: 0;
                        padding-top: 21px;
                        h2 {
                            transform: translateY(-150%);
                        }
                        h1 {
                            transform: translateY(-150%);
                        }
                        p {
                            transform: translateY(-150%);
                        }
                    }
                }
            }
        }
        .instagram-bg {
            .instgram-flex {
                .insta-top-flex {
                    flex-direction: column;
                    h2 {
                        margin-bottom: 40px;
                    }
                    .right-side {
                        flex-direction: column;
                        align-items: start;
                        .icon0link-flex {
                            margin-bottom: 16px;
                            a {
                                padding-left: 0;
                            }
                        }
                    }
                }
            }
        }
        .instagram-bg .instgram-flex .instagram-images .img-hover img {
            height: 250px;
        }
    }
    .horizontal-team-callout {
        .team-flex {
            .team-profile {
                width: 33%;
            }
        }
    }
    .featured-team-callout {
        .vertical-line {
            display: none;
        }
        .letter {
            display: none;
        }
    }
    img.mfp-img {
        max-width: 100%;
    }
    .blog-left-callout {
        .title {
            max-width: unset;
            position: relative;
            left: unset;
            top: unset;
            padding-left: 15px;
        }
        .content-wrapper {
            max-width: unset;
            border-left: none;
            padding-left: 15px;
            padding-right: 15px;
            margin-top: 50px;
            margin-left: unset;
            position: relative;
            top: unset;
            transform: none;
        }
        .paroller-wrapper {
            .paroller {
                min-height: unset;
            }
        }
    }
    .blog-right-callout {
        .title {
            max-width: unset;
            position: relative;
            left: unset;
            top: unset;
            padding-left: 15px;
            text-align: left;
            right: unset;
        }
        .content-wrapper {
            max-width: unset;
            border-left: none;
            padding-left: 15px;
            padding-right: 15px;
            margin-top: 50px;
            margin-left: unset;
            position: relative;
            top: unset;
            transform: none;
        }
        .paroller-wrapper {
            .paroller {
                min-height: unset;
            }
        }
    }
    // about slider
    .about-slider-callout {
        .horizontal-line-right {
            width: 350px;
        }
        .horizontal-line-left {
            width: 230px;
        }
        .container-dots {
            right: 20px;
        }
        .single-about-slider-callout {
            h1 {
                font-size: 38px;
            }
        }
        .copy-animated {
            animation-delay: 0s;
        }
    }
    .join-the-team-callout {
        .flex-join {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

// Medium devices (tablets, less than 992px)
@include media-breakpoint-down(md) {
    .site-footer {
        .navigation {
            text-align: center;
            .disclaimer {
                text-align: center;
                padding-top: 30px;
            }
        }
    }
    .featured-slider-callout {
        .featured-slider {
            .title {
                padding: 0 15px;
                top: 33%;
                .name {
                    font-size: 70px;
                    min-height: 50px;
                }
            }
        }
        .slider-nav {
            bottom: 100px;
            top: unset;
            transform: unset;
        }
    }
    .image-content-callout {
        .content-wrapper {
            .content {
                max-width: 100%;
                border-left: none;
                padding-left: 0;
                margin-top: 100px;
                text-align: center;
            }
        }
    }
    .middle-content-callout {
        h2 {
            text-align: center;
            padding-right: 0;
            margin-top: 0;
        }
        .border-left-brown {
            border-left: none;
            text-align: center;
        }
        .text-content {
            padding-top: 100px;
        }
    }
    .horizontal-team-callout {
        .team-flex {
            .team-profile {
                width: 50%;
            }
        }
    }
    .featured-team-callout {
        padding-top: 100px;
        .side-text-right {
            margin-bottom: 60px;
        }
        .image-wrapper {
            position: relative;
            margin: 0 auto;
        }
        .content-wrapper {
            padding-bottom: 50px;
            padding-top: 0;
            text-align: center;
            float: none;
            max-width: 100%;
            position: relative;
            transform: none;
            padding-left: 0;
        }
        .content {
            display: block;
            max-width: 100%;
            .more {
                border-left: none;
            }
        }
    }
    .horizontal-team-callout {
        .horizontal-line {
            display: none;
        }
        .letters {
            display: none;
        }
        .content {
            margin-top: 30px;
            text-align: center;
            max-width: 100%;
            .more {
                border-left: none;
                padding: 0;
            }
        }
        .image-wrapper {
            margin: 0 auto;
        }
        .title-wrapper {
            position: relative;
            margin: 10px auto;
            text-align: center;
            right: 0;
        }
        .line {
            margin: 15px auto;
        }
        .team-wrapper {
            padding-top: 0;
            padding-bottom: 0;
            margin-right: 0;
            margin-left: 0;
            margin-top: 10px !important;
            .team-profile {
                margin-top: 10px !important;
                .img-fluid {
                    max-width: 190px;
                }
                .user-info {
                    right: 0;
                    max-width: 140px;
                    position: absolute;
                    bottom: 0;
                    left: 220px;
                }
            }
            .small-team {
                max-width: 100%;
            }
        }
    }
    .featured-projects-callout {
        .item {
            min-height: unset;
            height: 400px;
        }
    }
    .projects-archive-callout {
        .filters-button-group {
            .button {
                display: block;
                text-align: center;
                margin: 10px auto;
                &:last-child {
                    margin-right: auto;
                }
            }
        }
        .side-text-left {
            top: 0;
        }
    }
    .single-project {
        .highlights {
            padding-top: 100px;
            h2 {
                margin-bottom: 20px;
                margin-top: 0;
                margin-left: 0;
                max-width: 100%;
                text-align: center;
            }
            h3 {
                max-width: 100%;
                text-align: center;
            }
            .description-wrapper {
                position: relative;
                border-left: none;
                padding-left: 0;
                max-width: 100%;
                left: 0;
                text-align: center;
                padding-bottom: 30px;
            }
        }
        .galleries {
            h2 {
                text-align: center;
                color: $brown;
                display: block !important;
            }
            .content-wrapper {
                padding-right: 0;
                border-right: none;
                position: relative;
                text-align: center;
                max-width: 100%;
                right: 0;
                bottom: 0;
                padding-top: 30px;
                padding-bottom: 30px;
            }
            .title-right {
                display: none;
            }
            .title-left {
                display: none;
            }
            .line-left {
                display: none;
            }
            .line-right {
                display: none;
            }
            .p-l-0 {
                padding-left: 15px !important;
            }
        }
    }
    .single-project {
        .paroller-wrapper {
            max-height: unset;
        }
    }
    .widget-area {
        .sidebar-newsletter {
            margin-top: 30px;
            h4 {
                max-width: 100%;
            }
        }
    }
    .single-post {
        .site-content {
            h2 {
                text-align: center;
                max-width: 100%;
            }
            .featured-thumbnail {
                padding-bottom: 30px;
                padding-right: 0;
            }
            .entry-content {
                padding-left: 0;
                border-left: none;
                padding-right: 0;
            }
        }
    }
    .image-content-callout {
        .image-content {
            background-size: contain;
        }
    }
    .blog-slider-callout {
        .side-text-left {
            padding-top: 100px;
        }
        .container-md {
            min-height: calc(100vh - 200px);
        }
        .vertical-line {
            display: none;
        }
    }
    .blog-middle-callout {
        .counter-2 {
            .middle-content {
                padding-left: 30px;
            }
        }
        .horizontal-line {
            display: none;
        }
    }
    // about slider
    .about-slider-callout {
        height: auto;
        min-height: calc(100vh - 80px);
        .image-content-callout {
            .image-content {
                background-size: contain;
            }
        }
        .slick-dots {
            text-align: center;
            li {
                display: inline-block;
            }
        }
        .container-dots {
            text-align: center;
            margin: 0 auto;
            right: unset;
        }
        .body-copy {
            position: relative;
            z-index: 10;
            top: unset !important;
            left: unset !important;
            right: unset !important;
            bottom: unset !important;
            transform: none !important;
            text-align: center !important;
            max-width: 100%;
            padding-top: 100px;
        }
        .svg-image {
            display: none;
        }
        .image-animated {
            display: none !important;
        }
        .left-content {
            display: none !important;
            position: relative;
            top: 500px !important;
            transform: none !important;
            text-align: center !important;
            left: unset !important;
        }
        .right-content {
            display: none !important;
            position: relative;
            top: 500px !important;
            transform: none !important;
            text-align: center !important;
            right: unset !important;
        }
        .information {
            position: relative;
            width: 100%;
            left: unset !important;
            top: unset !important;
            transform: unset !important;
        }
        .horizontal-line-right {
            display: none;
        }
        .horizontal-line-left {
            display: none;
        }
    }
    .side-text {
        position: relative;
        top: 0;
        transform: none;
        text-align: center;
        margin-left: 0;
        margin-right: 0;
        writing-mode: unset;
        text-orientation: unset;
        .inner-text {
            transform: none;
        }
    }
    /* disable the parallax effect on mobiles */
    *[data-paroller-factor] {
        /*for all elements with background parallax effect*/
        background-position: center !important;
        /*for all elements with foreground parallax effect*/
        transform: none !important;
    }
    .mobile-menu {
        display: block;
    }
    .desktop-menu {
        display: none;
    }
    .featured-slider-callout .slider-nav-items .item {
        width: 250px;
    }
    .featured-slider-callout .slider-progress {
        width: 250px;
    }
    .featured-slider-callout .slider-nav-items .slick-slide {
        width: 250px !important;
    }
    .join-the-team-callout {
        .flex-join {
            flex-direction: column;
            .join-title {
                width: 100%;
                padding-bottom: 16px;
                &::before {
                    width: 184px;
                    height: 1px;
                    top: 100%;
                    right: unset;
                    left: 50%;
                    transform: translateX(-50%);
                }
                h2 {
                    text-align: center;
                }
            }
            .join-content {
                padding-top: 80px;
                width: 100%;
                padding-left: 0;
                h4 {
                    text-align: center;
                }
                p {
                    text-align: center;
                }
                a {
                    margin: auto;
                    display: block;
                    width: fit-content;
                }
            }
        }
    }
    .broker-page-template {
        .flex-portals {
            .portal-block {
                width: 100%;
                padding: 0;
            }
        }
    }
    .broker-page-internal-template {
        .show-portal-flex {
            flex-direction: column;
            .left-img {
                width: 100%;
                margin-bottom: 48px;
            }
            .right-side-portal {
                width: 100%;
                margin-left: 0;
                padding-left: 0;
                &::before {
                    display: none;
                }
            }
        }
    }
    .about-us-template {
        .banner-about {
            .container {
                .row {
                    .col-12 {
                        .side-text {
                            display: none;
                        }
                        .copy-banner-about {
                            h2 {
                                font-size: 44px;
                                &::after {
                                    width: 120px;
                                }
                            }
                        }
                        .discover {
                            display: none;
                        }
                    }
                }
            }
        }
        .container-scroll-tl {
            .screen-block-to-move {
                .first-hor-scr {
                    .flex-in-slide {
                        flex-direction: column;
                        h4 {
                            text-align: center;
                            margin-bottom: 24px;
                            br {
                                display: none;
                            }
                        }
                        h3 {
                            text-align: center;
                            margin-bottom: 24px;
                            br {
                                display: none;
                            }
                        }
                        .right-side {
                            padding-left: 0;
                            padding-top: 24px;
                            &::before {
                                width: 120px;
                                height: 1px;
                                left: 50%;
                                transform: translateX(-50%);
                            }
                            p {
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
        .full-page-slider-with-color {
            .slide {
                min-height: 80vh;
                .flex-in-slide {
                    flex-direction: column;
                    h4 {
                        text-align: center;
                        margin-bottom: 24px;
                        br {
                            display: none;
                        }
                    }
                    .right-side {
                        padding-left: 0;
                        padding-top: 24px;
                        &::before {
                            width: 120px;
                            height: 1px;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                        p {
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
    .home-template {
        .instagram-bg {
            .instgram-flex {
                .instagram-images {
                    .img-no-hov {
                        width: 33.3%;
                    }
                }
            }
        }
    }
}

// Small devices (landscape phones, less than 768px)
@include media-breakpoint-down(sm) {
    .site-footer {
        .footer-flex {
            flex-direction: column;
            .left-side {
                .navbar-brand {
                    width: fit-content;
                    margin: auto;
                    padding-bottom: 48px;
                }
            }
        }
        .social-media {
            padding-top: 0;
            margin: auto;
            display: block;
            width: fit-content;
            margin-bottom: 80px;
        }
        .right-side {
            .contact-flex {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .widget-title {
                    padding-right: 0;
                }
                .address {
                    padding-left: 0;
                    &::before {
                        display: none;
                    }
                }
            }
        }
        .navigation {
            .footer-list {
                li {
                    padding-right: 8px;
                }
            }
        }
    }
    .horizontal-team-callout {
        .team-flex {
            .team-profile {
                width: 100%;
                img {
                    height: 550px;
                }
            }
        }
    }
    h2 {
        font-size: 38px;
    }
    .home-template {
        .main-slide-cont {
            .main-slider {
                .slide {
                    .left-side {
                        h2 {
                            font-size: 96px;
                            span {
                                font-size: 88px;
                            }
                        }
                    }
                    .right-side {
                        p {
                            font-size: 25px;
                        }
                        h2 {
                            font-size: 96px;
                            span {
                                font-size: 40px;
                            }
                        }
                        h1 {
                            font-size: 96px;
                            span {
                                font-size: 40px;
                            }
                        }
                    }
                }
            }
        }
        .legacy-slider {
            .legacy-bg {
                .flex-legacy {
                    .left-side {
                        h2 {
                            font-size: 40px;
                        }
                        h1 {
                            font-size: 40px;
                        }
                    }
                }
            }
            .awards-bg {
                .flex-awards {
                    .right-side {
                        .award {
                            h3 {
                                font-size: 32px;
                            }
                            h4 {
                                font-size: 16px;
                            }
                            h5 {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
        .instagram-bg {
            .instgram-flex {
                .insta-top-flex {
                    h2 {
                        font-size: 40px;
                    }
                }
            }
        }
        .instagram-bg {
            .instgram-flex {
                .instagram-images {
                    .img-no-hov {
                        width: 50%;
                    }
                }
            }
        }
    }
    .featured-team-callout {
        .full-w {
            padding-right: 0;
        }
        .right-side-text {
            .right-side-in {
                padding-left: 0;
                margin-bottom: 40px;
                &::after {
                    display: none;
                }
            }
        }
    }
    .site-footer {
        .border-widget {
            border-right: none;
            border-left: none;
        }
        .navbar-brand {
            padding-bottom: 50px;
        }
    }
    .single-post {
        .site-content {
            .side-text-left {
                top: 0;
            }
        }
    }
    .featured-slider-callout {
        .featured-slider {
            .title {
                top: 33%;
                .name {
                    font-size: 40px;
                    min-height: 50px;
                    letter-spacing: -3px;
                }
            }
        }
    }
    .p-b-160 {
        padding-bottom: 100px !important;
    }
    .p-t-150 {
        padding-top: 100px !important;
    }
    .counter-2 {
        padding-top: 100px;
    }
    .counter-3 {
        padding-top: 100px;
    }
    .featured-slider-callout {
        .slider-nav-items {
            .slick-slide {
                margin-bottom: 10px;
            }
            .title {
                padding-left: 15px;
            }
        }
    }
    .site-footer {
        .social-media {
            position: relative;
        }
    }
    .join-the-team-callout {
        .flex-join {
            .join-title {
                h2 {
                    font-size: 32px;
                }
            }
            .join-content {
                h4 {
                    font-size: 22px;
                }
            }
        }
    }
    .broker-page-template {
        .password-area {
            flex-direction: column;
            h3 {
                width: 100%;
            }
            h2 {
                width: 100%;
            }
            .pass-section {
                width: 100%;
            }
        }
    }
    .projects-archive-callout h1 {
        font-size: 38px;
    }
    .contact h1 {
        font-size: 38px;
    }
    .privacy-policy h1 {
        font-size: 38px;
    }
    .thank-you h1 {
        font-size: 38px;
    }
    
}

// Extra small devices (portrait phones, less than 576px)
@include media-breakpoint-down(xs) {
    .about-us-template {
        .banner-about {
            .container {
                .row {
                    .col-12 {
                        .copy-banner-about {
                            h2 {
                                font-size: 24px;
                            }
                        }
                    }
                }
            }
        }
        .container-scroll-tl {
            .screen-block-to-move {
                .first-hor-scr {
                    h2 {
                        font-size: 40px;
                    }
                    .flex-in-slide {
                        flex-direction: column;
                        h3 {
                            font-size: 24px;
                        }
                    }
                }
            }
        }
        .full-page-slider-with-color {
            .slide {
                h3 {
                    font-size: 40px;
                }
                h1 {
                    font-size: 40px;
                }
                .flex-in-slide {
                    h4 {
                        font-size: 24px;
                    }
                }
            }
        }
    }
    .featured-team-callout {
        padding-left: 30px;
        padding-right: 30px;
        h1 {
            font-size: 32px;
        }

        .team-title {
            h3 {
                font-size: 22px;
            }
            h2 {
                font-size: 22px;
            }
            h2.text-brown {
                font-size: 32px;
            }
        }
    }
    .horizontal-team-callout {
        .team-flex {
            .team-profile {
                width: 100%;
            }
        }
    }
    .site-footer {
        .header-logo {
            margin: 0 auto;
        }
        .contact-info {
            text-align: center;
        }
        .btn-primary {
            margin: 0 auto;
        }
    }
    .about-slider-callout {
        min-height: unset;
        .column-container {
            height: unset;
        }
        .image-content-callout {
            .content-wrapper {
                height: unset;
            }
        }
        .container-dots {
            top: 60px;
        }
    }
    .single-about-slider-callout {
        height: unset;
        .content-wrapper {
            height: unset;
        }
    }
    .broker-page-template {
        h1 {
            font-size: 40px;
        }
        .line-with-flex {
            flex-direction: column;
            h3 {
                font-size: 24px;
                padding-right: 0;
                padding-bottom: 16px;
                br {
                    display: none;
                }
            }
            .with-line-left {
                padding-left: 0;
                padding-top: 24px;
                &::before {
                    width: 183px;
                    height: 1px;
                    left: 50%;
                    transform: translateX(-50%);
                }
                p {
                    text-align: center;
                }
            }
        }
    }
    .broker-page-internal-template {
        h1 {
            font-size: 40px;
            margin-top: 40px;
        }
        .show-portal-flex {
            margin-top: 40px;
            .show-portal-flex {
                .right-side-portal {
                    h3 {
                        text-align: center;
                    }
                }
            }
        }
        .accordion-box {
            .each-accordion>div {
                ul {
                    padding: 0;
                    .flex-line {
                        flex-direction: column;
                        .right-side {
                            margin-top: 24px;
                            justify-content: space-between;
                        }
                    }
                }
            }
        }
    }
}


/* Portrait and Landscape */

@media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
    .featured-slider-callout .slider-nav {
        top: 80%;
    }
}

@media screen and (max-height: 850px) {
    .featured-team-callout {
        padding-top: 100px;
    }
}