.image-nav-callout {
  .image-content {
    min-height: 560px;
    background-size: cover;
    background-position: center;
    color: $white;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
  }

  .container {
    position: relative;
  }

  a {
    color: $white;
  }

  .content-wrapper {
    position: relative;
    min-height: 560px;
    cursor: pointer;

    h2 {

    }


    .title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      width: 100%;
    }

    .content {
      max-width: 460px;
      padding-bottom: 20px;
      padding-top: 36px;
      margin: 0 auto;
    }

  }
}