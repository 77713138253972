.single-project {
  .site-content {
    padding-top: 0;
    background-color: $light-black;
  }

  .navbar {
    border-bottom: 0.5px solid $light-grey;
  }

  .paroller-wrapper {
    width: 100%;
    max-height: 554px;
    overflow: hidden;

    .paroller {
      width: 100%;
      min-height: 554px;
      background-size: cover;
      background-position: bottom center;
      position: relative;
      background-repeat: no-repeat;
    }
  }

  .entry-header {
    height: 100vh;
    background-size: cover;
    background-position: center center;
    position: relative;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, .5);
    background-repeat: no-repeat;

    .container {
      position: relative;
      height: 100%;
    }

    .title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      width: 100%;

      .name {
        color: #fff;
        font-family: $miller;
        text-transform: uppercase;
        min-height: 115px;
        max-width: 900px;
        margin: 0 auto;
        text-align: center;
        color: #fff;
      }

      .address {
        font-size: 17px;
        color: $white;
        letter-spacing: 8px;
        text-transform: uppercase;
        font-weight: 500;
        color: #fff;
      }
    }
  }

  .highlights {
    position: relative;
    padding-top: 300px;
    padding-bottom: 100px;
    color: $silver;
    h2 {
      color: $silver;
      margin-top: -100px;
      margin-left: -70px;
      max-width: 400px;
    }

    h3 {
      color: $silver;
      max-width: 200px;
    }

    .description-wrapper {
      position: absolute;
      bottom: 0;
      left: 40px;
      color: $white;
      border-left: 1px solid $silver;
      padding-left: 15px;
      max-width: 350px;

      p:last-child {
        margin-bottom: 0;
      }

      .btn {
        margin-top: 1rem;
      }
    }

    .project-logo {
      max-width: 100px;
      margin-bottom: 20px;
    }
  }

  .galleries {
    position: relative;
    padding-top: 100px;
    padding-bottom: 100px;

    .container {
      position: relative;
    }

    .line-left {
      // height: 1px;
      background-color: $silver;
      // position: absolute;
      // width: 1100px;
      // top: 340px;
      // opacity: 0;
    }

    .line-right {
      // height: 1px;
      background-color: $silver;
      // position: absolute;
      // width: 1100px;
      // top: 673px;
      // right: 0;
      // opacity: 0;
    }

    .title-left {
      font-family: $miller;
      // font-size: 130px;
      // color: $brown;
      // position: absolute;
      // z-index: 9;
      // left: -35px;
      // letter-spacing: 20px;
      // top: 230px;
      color: $silver;
    }

    .title-right {
      font-family: $miller;
      font-size: 130px;
      color: $brown;
      position: absolute;
      z-index: 9;
      right: -35px;
      letter-spacing: 20px;
      top: 566px;
    }

    .content-wrapper {
      border-right: 1px solid $brown;
      padding-right: 15px;
      max-width: 270px;
      color: $white;
      text-align: right;
      right: 40px;
      position: absolute;
      bottom: 50px;
    }

    .gallery-right {
      .gallery-item {
        width: 50%;
        background-size: cover;
        height: 215px;
        background-position: center;
        float: left;
      }

      .full-width {
        width: 100%;
        height: 300px
      }

      a:nth-child(even) img {
        max-width: 50%;
        display: inline-block;
        float: right;
      }
    }

    .gallery-left {
      .gallery-item {
        width: 50%;
        background-size: cover;
        height: 215px;
        background-position: center;
        float: left;
      }

      .full-width {
        width: 100%;
        height: 300px
      }

      a:nth-child(even) img {
        max-width: 50%;
        display: inline-block;
        float: right;
      }
    }
  }

  .featured-projects-callout {
    background-color: $light-black;

    h2 {
      text-align: center;
      padding-top: 50px;
      padding-bottom: 50px;
      color: $brown;
    }
  }
}

// custom code for union village page
.unionvillage {
  .entry-header {
    .title {
      .name {
        max-width: unset;
      }
    }
  }
}

