.horizontal-team-callout {
  background-color: $light-black;
  padding-top: 100px;
  padding-bottom: 100px;
  overflow-y: hidden;
  .team-flex {
    display: flex;
    flex-wrap: wrap;
    .team-profile {
      width: 25%;
      padding: 0 15px;
      margin-bottom: 24px;
      .team-in {
        position: relative;
        overflow: hidden;
        &:hover {
          img {
            transform: scale(1.1);
          }
        }
      }
      img {
        width: 100%;
        height: 400px;
        object-fit: cover;
        transition: 1s;
      }
      .user-info {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: rgba(0,0,0,0.5);
        padding: 22px 24px;
        max-height: 114px;
        h3 {
          color: $white;
          margin-bottom: 8px;
          font-size: 24px;
        }
        p {
          color: $white;
          line-height: 1;
        }
      }
    }
  }

  .image-wrapper {
    max-width: 216px;
  }

  .container-md {
    position: relative;
  }

  .profile-wrapper {
    position: relative;
  }

  h2 {
    font-size: 46px;
  }

  p {
    color: $white;
  }

  .line {
    width: 155px;
    height: 1px;
    background-color: $brown;
    margin-top: 15px;
    margin-bottom: 5px;
  }

  .title-wrapper {
    position: absolute;
    bottom: 0;
    right: -8px;
    max-width: 200px;
  }

  .content {
    color: $white;
    max-width: 450px;

    .more {
      padding: 0 15px;
      border-left: 1px solid $brown;
    }
  }

  .horizontal-line {
    width: 1px;
    background-color: #2D2D2D;
    height: 9000px;
    position: absolute;
    top: -100px;
    left: 70px;
  }

  .team-wrapper {
    padding-top: 70px;
    padding-bottom: 130px;
    margin-right: 0;
    margin-left: 0;

    .team-profile {
      padding: 0;
      position: relative;
      height: 285px;

      .profile-image {
      }

      .user-info {
        right: -115px;
        max-width: 140px;
        position: absolute;
        bottom: -60px;
        h3 {
          color: $brown;
          margin-bottom: 0;
          &:after {
            display: block;
            content: "";
            border-top: 1px solid $brown;
            width: 100px;
            margin-top: 15px;
            -webkit-transform: translateY(-0.5rem);
            transform: translateY(-0.5rem);
          }
        }
      }
    }
    .small-team {
      max-width: 196px;
    }
  }
}