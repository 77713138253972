/*
/* only include the WOFF and WOFF2 formats
*/

@import url("https://use.typekit.net/mvq8gsl.css");
@font-face {
    font-family: 'MillerBanner';
    src: url('../fonts/miller/MillerBanner-Light.woff2') format('woff2'), url('../fonts/miller/MillerBanner-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'MillerBanner';
    src: url('../fonts/miller/MillerBanner-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir';
    src: url('../fonts/avenir/Avenir-Book.woff2') format('woff2'), url('../fonts/avenir/Avenir-Book.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir';
    src: url('../fonts/avenir/Avenir-Medium.woff2') format('woff2'), url('../fonts/avenir/Avenir-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}