.middle-content-callout{
  .text-content {
    color: $white;
    background-color: $light-black;
    padding-top: 190px;
    padding-bottom: 110px;
  }

  h2{
    color: $brown;
    text-align: right;
    padding-right: 20px;
    margin-top: -58px;
    padding-bottom: 20px;
  }
  h3{
    color: $brown;
  }
  .container{
    position: relative;
  }
}