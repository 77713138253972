/**
 * contact form 7 styles
 */
.wpcf7 {
  margin: 0;
  padding: 0;

  .screen-reader-response {
    position: absolute;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    width: 1px;
    margin: 0;
    padding: 0;
    border: 0;
  }

  .wpcf7-response-output {
    margin: 2em 0.5em 1em;
    padding: 0.2em 1em;
    border: 2px solid $danger;
  }

  .wpcf7-mail-sent-ok {
    border: 2px solid $success;
  }

  .wpcf7-mail-sent-ng,
  .wpcf7-aborted {
    border: 2px solid $danger;
  }

  .wpcf7-spam-blocked {
    border: 2px solid $warning;
  }

  .wpcf7-validation-errors,
  .wpcf7-acceptance-missing {
    border: 2px solid $warning;
  }

  .wpcf7-form-control-wrap {
    position: relative;
  }

  .wpcf7-not-valid-tip {
    color: $danger;
    font-size: 1em;
    font-weight: normal;
    display: block;

    .wpcf7-not-valid-tip {
      position: absolute;
      top: 20%;
      left: 20%;
      z-index: 100;
      border: 1px solid $danger;
      background: #fff;
      padding: .2em .8em;
    }
  }

  .wpcf7-list-item {
    display: inline-block;
    margin: 0 0 0 1em;
  }

  .wpcf7-list-item-label::before,
  .wpcf7-list-item-label::after {
    content: " ";
  }

  .wpcf7-display-none {
    display: none;
  }

  .ajax-loader {
    visibility: hidden;
    display: inline-block;
    width: 16px;
    height: 16px;
    border: none;
    padding: 0;
    margin: 0 0 0 4px;
    vertical-align: middle;
  }

  .ajax-loader.is-active {
    visibility: visible;
  }

  .ajax-error {
    display: none;
  }

  .placeheld {
    color: #888;
  }

  .wpcf7-recaptcha iframe {
    margin-bottom: 0;
  }

  input[type="file"] {
    cursor: pointer;
  }

  input[type="file"]:disabled {
    cursor: default;
  }
}


.form-disclaimer {
  font-size: 10px;
  line-height: 130%;
}

.form-control {
  background-color: $silver;
  border: none;
  color: $white;
}

.form-group {
  margin-bottom: 20px;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $form-placeholder;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $form-placeholder;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: $form-placeholder;
}

.form-disclaimer {
  color: $white;
}

textarea {
  min-height: 140px;
}

.contact-form{
  color: $white;
  .form-control{
    color: $white;
    background-color: transparent;
    border-bottom: 0.5px solid $silver;
    padding: 0;
  }
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $white;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $white;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: $white;
  }
  textarea{
    border: 0.5px solid $brown;
    padding: 5px !important;
  }

}