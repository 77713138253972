.about-slider-callout {
  height: calc(100vh - 80px);

  .column-container {
    height: calc(100vh - 80px);
  }

  .image-content-callout {
    .content-wrapper {
      position: relative;
      height: calc(100vh - 80px);
    }

    .image-content {
      background-size: contain;
    }
  }

  .container-dots {
    position: absolute;
    top: 50px;
    width: 100%;
    right: -10px;
  }

  .slide {
    height: calc(100vh - 80px);
    position: relative;
    outline: none;

    h1 {
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      left: 50%;
    }
  }

  .slick-dots {
    position: relative;
    bottom: 0;
    text-align: right;
    top: 0;
    margin: 0 auto;
    max-width: 1330px;

    li {
      display: block;
      margin-left: auto;
    }

    li button:before {
      font-size: 20px;
    }
  }

  .slick-vertical {
    .slick-slide {
      border: none;
    }
  }

  .dark-overlay {
    .image-content {
      box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
    }
  }

  .slick-slide {
    opacity: 0;
    transition: opacity 1s ease-out;
  }

  .slick-active {
    opacity: 1 !important;
    transition: opacity 1s ease-in;
  }

  .slick-cloned {
    opacity: 0.3;
  }

  .right-content {
    position: absolute;
    top: 50%;
    right: 150px;
    transform: translate(0, -50%);

    h3 {
      max-width: 100%;
    }
  }

  .left-content {
    position: absolute;
    top: 50%;
    left: 50px;
    transform: translate(0, -50%);
    text-align: right;

    h3 {
      max-width: 100%;
    }
  }

  .horizontal-line-left {
    background-color: $brown;
    height: 1px;
    width: 35%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
  }

  .horizontal-line-right {
    background-color: $brown;
    height: 1px;
    width: 35%;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
  }

  .body-copy {
    max-width: 340px;
    color: $white;
    position: absolute;
    z-index: 10;

    h4 {
      color: $brown;
      margin-bottom: 27px;
    }
  }

  .top-left-copy {
    .body-copy {
      text-align: right;
      top: 45%;
      transform: translate(0, calc(-45% - 150px));
      left: 210px;
    }
  }

  .top-right-copy {
    .body-copy {
      text-align: left;
      top: 45%;
      transform: translate(0, calc(-45% - 150px));
      right: 210px;
    }
  }

  .bottom-right-copy {
    .body-copy {
      text-align: left;
      bottom: 100px;
      right: 210px;
    }
  }

  .bottom-left-copy {
    .body-copy {
      text-align: right;
      bottom: 100px;
      left: 160px;
    }
  }

  .top-left-image {
    background-image: url("../images/about/top-left.png");
    background-size: cover;
    width: 200px;
    height: 200px;
    position: absolute;
    top: calc(50% - 200px);
    left: calc(50% - 200px);
    transform: translate(0, -50%);
  }

  .top-right-image {
    background-image: url("../images/about/top-right.png");
    background-size: cover;
    width: 200px;
    height: 200px;
    position: absolute;
    top: calc(50% - 200px);
    right: calc(50% - 200px);
    transform: translate(0, -50%);
  }

  .bottom-right-image {
    background-image: url("../images/about/bottom-right.png");
    background-size: cover;
    width: 200px;
    height: 200px;
    position: absolute;
    top: calc(50% + 0px);
    right: calc(50% - 200px);
    transform: translate(0, -50%);
  }

  .bottom-left-image {
    background-image: url("../images/about/bottom-left.png");
    background-size: cover;
    width: 200px;
    height: 200px;
    position: absolute;
    top: calc(50% + 0px);
    left: calc(50% - 200px);
    transform: translate(-50%, -50%);
  }

  .image-animated {
    animation-delay: 1s;
  }
  .copy-animated{
    animation-delay: 2s;
  }
  .slick-dots li.slick-active button:before {
    color: $brown;
    opacity: 1;
  }
  .slick-dots li button::before {
    color: $white;
    opacity: 1;
  }
  .slider-num{
    .slick-dots li button::before {
      color: $light-black;
      opacity: 1;
    }
    .slick-dots li.slick-active button:before {
      color: $brown;
      opacity: 1;
    }
  }
  .slick-dotted.slick-slider {
    margin-bottom: 0;
  }
}