/*--------------------------------------------------------------
Shortcode: Blog Ajax Callout
@author: Sohrab
--------------------------------------------------------------*/
.blog-callout {
  // single blog item
  .blog-item {
    // post meta data
    .meta-data {
      // category
      .category {
      }
      // time
      .time {
      }
    }
    // post title
    .title {
    }
    // post content
    .content {
    }
    // read more
    .read-more {
    }
  }
  // load more posts
  .load-more {
  }
  // the spinner
  .spinner {
    width: 40px;
    height: 40px;
    position: relative;
    margin: 100px auto;
    .double-bounce1,
    .double-bounce2 {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: $primary;
      opacity: 0.6;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-animation: sk-bounce 2s infinite ease-in-out;
      animation: sk-bounce 2s infinite ease-in-out;
    }

    .double-bounce2 {
      -webkit-animation-delay: -1s;
      animation-delay: -1s;
    }

    @-webkit-keyframes sk-bounce {
      0%,
      100% {
        -webkit-transform: scale(0);
      }
      50% {
        -webkit-transform: scale(1);
      }
    }

    @keyframes sk-bounce {
      0%,
      100% {
        transform: scale(0);
        -webkit-transform: scale(0);
      }
      50% {
        transform: scale(1);
        -webkit-transform: scale(1);
      }
    }
  }
}
