.featured-team-callout {
  min-height: calc(100vh - 80px);
  background-color: transparent;
  color: $white;

  h1 {
    font-size: 40px;
    color: $white;
    text-transform: uppercase;
    margin-bottom: 12px;
    padding-top: 10px;
  }

  .team-title {
    h3 {
      color: $silver;
      font-size: 30px;
    }
    h2 {
      color: $silver;
      font-size: 30px;
    }
    h2.text-brown {
      font-size: 40px;
      color: $white;
      text-transform: uppercase;
      margin-bottom: 12px;
      padding-top: 10px;
    }
  }
  .right-side-text {
    .right-side-in {
      margin-top: 72px;
      padding-left: 64px;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 1px;
        background-color: $silver;
      }
    }
    p {
      padding-bottom: 10px;
    }
    
  }
  .full-w {
    padding-right: 75px;
    img {
      width: 100%;
    }
  }
  .container {
    position: relative;
  }

  .container-md {
    min-height: calc(100vh - 80px);
    position: relative;
    max-width: 900px;
  }

  .content-wrapper {
    max-width: 600px;
    position: absolute;
    top: 55%;
    transform: translate(0%, -50%);
    padding-left: 15px;
  }

  .image-wrapper {
    max-width: 500px;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .content {
    max-width: 400px;

    
  }

  .letter {
    font-family: $miller;
    font-weight: 300;
    font-style: normal;
    margin-bottom: 0;
    font-size: 130px;
    position: absolute;
    color: $brown;
    top: 50%;

    &.c {
      left: 200px;
    }

    &.e {
      left: 400px;
    }

    &.o {
      right: 400px;
    }
  }

  .vertical-line {
    background-color: $brown;
    height: 1px;
    position: absolute;
    top: 50%;

    &.left {
      width: 468px;
      left: 0;
    }

    &.right {
      right: 0;
      width: 750px;
    }
  }

  .side-text-right {
    top: 60%;
  }
}