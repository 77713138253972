.navbar {
  background-color: $light-black;
  min-height: 80px;
  z-index: 99;
  border-bottom: 0.5px solid $light-grey;

  .container {
    position: relative;
  }
}

.header-navigation {
  position: relative;
}

/*  BURGER
========================================== */

.burger {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0;
  border-radius: 4px;
  z-index: 10;
  top: 0;
}

.burger span {
  margin-bottom: 9px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -1.5px;
}

.burger span, .burger span::before, .burger span::after {
  display: block;
  width: 30px;
  height: 3px;
  background-color: $white;
  outline: 1px solid transparent;
  -webkit-transition-property: background-color, -webkit-transform;
  -moz-transition-property: background-color, -moz-transform;
  -o-transition-property: background-color, -o-transform;
  transition-property: background-color, transform;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.burger span::before, .burger span::after {
  position: absolute;
  content: "";
}

.burger span::before {
  top: -9px;
}

.burger span::after {
  top: 9px;
}

.burger.clicked span {
  background-color: transparent;
}

.burger.clicked span::before {
  -webkit-transform: translateY(9px) rotate(45deg);
  -moz-transform: translateY(9px) rotate(45deg);
  -ms-transform: translateY(9px) rotate(45deg);
  -o-transform: translateY(9px) rotate(45deg);
  transform: translateY(9px) rotate(45deg);
}

.burger.clicked span::after {
  -webkit-transform: translateY(-9px) rotate(-45deg);
  -moz-transform: translateY(-9px) rotate(-45deg);
  -ms-transform: translateY(-9px) rotate(-45deg);
  -o-transform: translateY(-9px) rotate(-45deg);
  transform: translateY(-9px) rotate(-45deg);
}

.burger.clicked span:before, .burger.clicked span:after {
  background-color: #ffffff;
}

.burger:hover {
  cursor: pointer;
}

.nav-mobile {
  background-color: $brown;
  position: absolute;
  z-index: 9;
  top: 0;
  right: 0;
  height: 100%;
  max-width: 415px;
  width: 100%;
  padding: 100px 40px 60px 40px;
  overflow-y: auto;
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  .broker-portal {
    a {
      display: block;
      margin: 0;
      padding: 10px 24px;
      border: 1px solid $silver;
      border-radius: 25px;
      transition: 0.5s;
      font-size: 20px;
      &:hover {
        background-color: $silver;
      }
    }
  }
}

.nav-mobile.show {
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  -o-transform: translateX(0px);
  transform: translateX(0px);
}

.nav-mobile.show ul.main li {
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  -o-transform: translateX(0px);
  transform: translateX(0px);
  opacity: 1;
}

@media (min-width: 667px) {

  .nav-mobile {
    padding: 120px 50px 70px 50px;
  }
}

.nav-mobile ul {
  list-style-type: none;
}

.nav-mobile {
  .logo {
    max-width: 200px;
    text-align: center;
    margin: 0 auto;
    display: none;
    padding-bottom: 30px;
  }
}

.nav-mobile {
  font-weight: normal;
  font-style: normal;
  text-align: center;
  height: 100vh;

  ul {
    padding: 0;
  }

  li {
    font-size: 28px;
    // font-family: $miller;
    border-bottom: solid 1px rgba(237, 237, 248, 0.19);
    padding-top: .75em;
    padding-bottom: .75em;
    text-align: left;
    margin: 0 auto;
    position: relative;
  }

  a {
    color: $white;
    text-decoration: none;

    &:hover {
      color: $white;
      text-decoration: none;
    }
  }

  .register-button a {
    padding: 10px 20px !important;
    margin-top: 30px !important;
    display: block;
    text-align: center;
    background-color: $primary;
    border-color: $primary;
  }

  .register-button.current-menu-item {
    &:after {
      border-top: none;
    }
  }

  .register-button {
    border-bottom: none;
  }

  .current_page_item {
    a {
      color: $silver;
    }
  }

  .sub-menu {
    display: none;
    padding-left: 25px;
  }

  .menu-parent-item {
    cursor: pointer;

    a {
      display: block;
      width: 100%;
    }

    > a:after {
      content: "+";
      position: absolute;
      right: 0;
      top: 20px;
      display: block;
      width: 20px;
      height: 20px;
      line-height: 1;
      text-align: center;
      color: $white;
      font-size: 20px;
    }

  }

  a.active-parent-menu:after {
    content: "-" !important;
  }

  .active-parent-menu {
    border-bottom: none !important;

    > a {
      font-weight: 900;
    }

    a:after {
      content: "-" !important;
    }
  }

  .sub-menu {
    li {
      list-style-type: square;
      margin-left: 0;
      padding-left: 0;

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100vh;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: $light-black;
  opacity: 0;
  visibility: hidden;
}

.overlay.show {
  opacity: 0.8;
  visibility: visible;
}

.mobile-menu {
  display: none;
}

.desktop-menu {
  display: flex;
  .broker-portal {
    margin-left: 40px;
    a {
      display: block;
      margin: 0;
      padding: 10px 24px;
      border: 1px solid $silver;
      border-radius: 25px;
      transition: 0.5s;
      &:hover {
        background-color: $silver;
      }
    }
  }
  a {
    color: $white !important;
    text-transform: uppercase;
  }

  .current-menu-item {
    a{
      color: $silver !important;
      position: relative;
      display: block;
      &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $silver;
      }
    }

    &:after {
      display: block;
      content: "";
      border-top: 1px solid $primary;
      margin: 0;
    }

  }

  .menu-item{
    margin-left: 24px;
    font-size: 13px;
  }
  .nav-link {
    padding: 0 !important;
  }
}